<template>
  <!-- <div class="content-field-phone"> -->
  <div>
    <v-row>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
        <p class="text-title"></p>
          <p class="text-information">
             <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
          </p>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <p class="text-title">
          <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
        </p>
        <p class="text-information">
          <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
        </p>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <p class="text-title">
          <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
        </p>
        <p class="text-information">
          <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
        </p>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <p class="text-title">
          <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
        </p>
        <p class="text-information">
          <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {

  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}

.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}
</style>